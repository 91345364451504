// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";
// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

//Froala style
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/languages/de.js";
import "froala-editor/js/third_party/image_tui.min.js";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { handleLogout } from "./redux/actions/auth";
import { handleOrg } from "./redux/actions/layout";
import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import { Link } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
Bugsnag.start({
  apiKey: "50add759782857c041ff0610dabb4027",
  plugins: [new BugsnagPluginReact()],
});
// ** Lazy load app
const LazyApp = lazy(() => import("./App"));
const organizationId = localStorage.getItem("orgId");
if (organizationId) {
  store.dispatch(handleOrg(organizationId));
} else {
  store.dispatch(handleLogout());
}
const BugsnagBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
localStorage.setItem("project_slug", process.env.REACT_APP_PROJECT);

const UnsecuredPage = () => (
  <div>
    <h1>
      If you see this page, Webb App link you have clicked on is under
      Clickjacking security attack.
    </h1>
    <h2>
      Please inform team with the reference of the application from where you
      clicked this link.
    </h2>
    <h2>
      Click
      <a
        href={window.self.location.href}
        title="Web Application"
        target="blank"
      >
        here
      </a>
      to access WebApp safely.
    </h2>
  </div>
);
if (window.self === window.top) {
  ReactDOM.render(
    // <BugsnagBoundary>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <ThemeContext>
            <IntlProviderWrapper>
              <ErrorBoundary>
                <LazyApp />
              </ErrorBoundary>
            </IntlProviderWrapper>
            <ToastContainer newestOnTop autoClose={4000} />
          </ThemeContext>
        </Suspense>
      </Provider>,
    // </BugsnagBoundary>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(<UnsecuredPage />, document.getElementById("root"));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
